import React, { useContext } from "react";
import { Grid, IconButton, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import { ReactChildren } from "../../interfaces/Common";
import { AuthContext } from "../../Helpers/Contexts/authContext";
import { useNavigate } from "react-router-dom";
import { AuthUser } from "../../interfaces/Contexts";
import SideNavTabs from "./Tabs";
import { useStyles } from "./styles";
import AppBreadCrumbs from "../BreadCrumbs";
import Image from "../../Assets/assetHelper";
import KenColors from "../../Helpers/Themes/kenColors";

const AppLayout: React.FunctionComponent<ReactChildren> = ({ children }) => {
  const classes = useStyles();
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const userData = JSON.parse(`${localStorage.getItem("user")}`) as AuthUser;
  const handleListClick = (path: string, index: number) => {
    navigate(path);
  };

  return (
    <Grid container spacing={3} style={{ padding: "40px 40px 40px 40px" }}>
      <Grid item xs={12} md={2}>
        <Paper className={classes.nav}>
          <div>
            <div style={{ margin: "36px 0px", textAlign: "center" }}>
              <img className={classes.profilImage} src={Image.Images.DefaultProfileImage} alt="profile" />
              <Typography component={"h6"} variant="largebold" color={KenColors.ClientBlack2}>
                {userData?.applicantName ? userData.applicantName : ""}
              </Typography>
           
            </div>
            <SideNavTabs handleListClick={handleListClick} />
          </div>
          <div>
            <ListItemButton
              className={classes.listItemButton}
              onClick={() => {
                dispatch({ type: "LOGOUT" });
              }}
            >
              <ListItemIcon sx={{ minWidth: "40px" }}>
                <LogoutIcon className={classes.listItemIcon} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="mediumthin">Logout</Typography>
              </ListItemText>
            </ListItemButton>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} md={10}>
        <Grid container spacing={3} lg={12}>
          <Grid item container spacing={3} xs={12} lg={12}>
            <Grid item xs={12} md={8}>
              <Paper className={classes.header} sx={{ borderRadius: "16px !important" }}>
                <AppBreadCrumbs />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper className={classes.actions}>
                <div style={{ width: "1px", height: "60px", background: KenColors.ClientBlue8 }} />
                <img alt="logo" className={classes.actionLogo} src={Image.Images.DefaultOrganizationLogo} />
                <div style={{ textAlign: "start" }}>
                  <Typography variant="medium" component={"h6"} color={KenColors.ClientBlack}>
                    Kenverse
                  </Typography>
                  <Typography variant="medium" component={"h6"} color={KenColors.ClientBlack}>
                    Admin
                  </Typography>
                </div>
              </Paper>
            </Grid>
          </Grid>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AppLayout;
