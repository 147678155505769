import { Breadcrumbs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import KenColors from "../../Helpers/Themes/kenColors";

const useStyles = makeStyles(() => ({
  listText: {
    fontSize: "20px !important",
    fontWeight: "600 !important",
    textDecoration: "none",
    textTransform: "capitalize",
    margin: "0px !important",
    padding: "0px !important",
  },
}));

const AppBreadCrumbs: React.FunctionComponent = () => {
  const { pathname, state } = useLocation();
  const classes = useStyles();
  const navigateHistory: Array<string> = pathname.split("/").filter((items) => {
    return items && items !== "";
  });
  const navigateState: null | string = state?.breadCrumbs;
  let customPath: string = "";

  return (
    <Breadcrumbs separator=">" style={{ color: KenColors.ClientBlack2 }}>
      {navigateHistory.map((items, index) => {
        customPath = customPath + `;${items}`;
        const isLastIndex = index === navigateHistory.length - 1;
        const newPath = customPath.replace(/;/g, "/");
        return index === navigateHistory.length - 1 && navigateState ? (
          <Typography key={index} className={classes.listText} color={KenColors.ClientBlack2}>
            {navigateState}
          </Typography>
        ) : (
          <Link key={index} style={{ color: isLastIndex ? KenColors.ClientBlack2 : KenColors.ClientBlue2 }} className={classes.listText} to={`${newPath}`}>
            {items}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default AppBreadCrumbs;
