import axios, { AxiosInstance } from 'axios';
import { KEY_ACCESS_TOKEN, KEY_CONFIG_URL, KEY_REFRESH_TOKEN, REFRESH_URL } from '../Helpers/constant';
import { LoginData } from '../interfaces/Auth';
import { Institute } from '../interfaces/Common';
import { ApplyJob, FormPostJob } from '../interfaces/Jobs';

let appConfig: { apiBaseUrl: string; tokenUrl: string } | undefined;
export let axiosInstance: AxiosInstance;
const configUrl = KEY_CONFIG_URL;

export const getAxiosInstance = (data: { config?: { apiBaseUrl: string; tokenUrl: string } }[]) => {
  appConfig = data[0]?.config;
  axiosInstance = axios.create({
    baseURL: 'https://portal-config-api.ken42.com/v1',
    timeout: 15000,
  });

  axiosInstance.interceptors.request.use((req) => {
    const token = localStorage.getItem(KEY_ACCESS_TOKEN);
    req.headers.Authorization = `Bearer ${token}`;
    return req;
  });

  axiosInstance.interceptors.response.use(
    (resp) => {
      return resp;
    },
    async function (error) {
      if (!error.response?.config && error.response?.status !== 401) {
        return error;
      }
      return axios({
        method: 'get',
        baseURL: `${appConfig?.tokenUrl}`,
      }).then(async (response) => {
        if (localStorage.refresh_token && error.response.status === 401) {
          const url = REFRESH_URL;
          try {
            const res = await axios.post(url, {
              refreshToken: localStorage.refresh_token,
            });
            localStorage.setItem(KEY_ACCESS_TOKEN, res?.data?.accessToken?.token);
            localStorage.setItem(KEY_REFRESH_TOKEN, res?.data?.refreshToken?.token);
            error.response.config.headers['Authorization'] = `Bearer ${res?.data?.accessToken?.token}`;
            return axios(error.response.config);
          } catch (err) {
            localStorage.clear();
            window.location.reload();
          }
        } else {
          error.response.config.headers['Authorization'] = `Bearer ${localStorage.getItem(KEY_ACCESS_TOKEN)}`;
          return axios(error.response.config);
        }
      });
    }
  );
};

export const getConfig = async () => {
  try {
    const path = 'dev-alumniportal.ken42.com';
    const res = await axios.get(`${configUrl}/${path}`);
    return res && res.data ? res.data : null;
  } catch (err) {
    return err;
  }
};

export const getOtp = async (Payload: Object) => {
  const path = `auth/forgot-password`;
  const res = await axiosInstance.post(path, Payload);
  return res && res.data ? res.data : res;
};

export const verifyOtpReset = async (email: string, otp: string) => {
  try {
    const path = `/auth/verify-otp?email=${email}&otp=${otp}`;
    const res = await axiosInstance.get(path);
    return res && res.data ? res.data : res;
  } catch (error) {
    return error;
  }
};

export const loginSSO = async (email: string) => {
  try {
    const path = `/auth/sso/user/verify?email=${email}`;
    const response = axiosInstance.get(path);
    return response;
  } catch (error) {
    return error;
  }
};

export const authLogin = async (data: any, email: String | null) => {
  const path = `/auth/login?email=${email}`;
  const res: any = await axiosInstance.post(path, data);
  return res && res.data ? res.data : res;
};

export const postUploadPicture = async (data: FormData) => {
  const path = `/file/file-upload`;
  const res: any = await axiosInstance.post(path, data);
  return res && res.data ? res.data : res;
};

export const resetPassword = async (data: LoginData) => {
  const path = `/auth/reset-password`;
  const res: any = await axiosInstance.post(path, data);
  return res && res.data ? res.data : res;
};

export const getFileUrl = async (data: File) => {
  const path = `/file/file-upload`;
  const form = new FormData();
  form.append('file', data);
  const res: any = await axiosInstance.post(path, form);
  return res && res.data ? res.data : res;
};

export const getOptions = async (objectName: string, fieldName: string) => {
  const path = `/helper/getDropDownValues?objectName=${objectName}&fieldName=${fieldName}`;
  const res: any = await axiosInstance.get(path);
  return res && res.data ? res.data : res;
};

export const getAllJobs = async (conatctId: string) => {
  const path = `/job/registred/job?contactId=${conatctId}`;
  const res: any = await axiosInstance.get(path);
  return res && res.data ? res.data : res;
};

export const getJob = async (jobId: string) => {
  const path = `/job/individual/job?jobId=${jobId}`;
  const res: any = await axiosInstance.get(path);
  return res && res.data ? res.data : res;
};

export const jobApply = async (payload: ApplyJob) => {
  const path = `/job/apply/job`;
  const res: any = await axiosInstance.post(path, payload);
  return res && res.data ? res.data : res;
};

export const postJob = async (payload: FormPostJob) => {
  const path = `/job/post/job`;
  const res: any = await axiosInstance.post(path, payload);
  return res && res.data ? res.data : res;
};

export const getClients = async () => {
  const path = `/clients`;
  const res: any = await axiosInstance.get(path);
  return res && res.data ? res.data : res;
};

export const createClients = async (payload: Institute) => {
  const path = `/clients`;
  const res: any = await axiosInstance.post(path, payload);
  return res && res.data ? res.data : res;
};

export const getSubClients = async (id: string | undefined) => {
  const path = `/configs/getConfig/${id}`;
  const res: any = await axiosInstance.get(path);
  return res && res.data ? res.data : res;
};

export const getSubClientConfigs = async (id: string) => {
  const path = `/configs/${id}`;
  const res: any = await axiosInstance.get(path);
  return res && res.data ? res.data : res;
};

export const postConfig = async (body: any) => {
  const path = `/configs`;
  const res: any = await axiosInstance.post(path, body);
  return res && res.data ? res.data : res;
};

export const patchConfig = async (body: any, id: String) => {
  const path = `/configs/${id}`;
  const res: any = await axiosInstance.patch(path, body);
  return res && res.data ? res.data : res;
};
