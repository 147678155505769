const Images = {
  Raise: "https://inazstgpfs3001.blob.core.windows.net/assets/Ken42%20Alumni/PNG/Group%2048099673.png",
  Education: "https://inazstgpfs3001.blob.core.windows.net/assets/Ken42%20Alumni/PNG/education.png",
  Medal: "https://inazstgpfs3001.blob.core.windows.net/assets/Ken42%20Alumni/PNG/medal-star.png",
  Credit: "https://inazstgpfs3001.blob.core.windows.net/assets/Ken42%20Alumni/PNG/two_credit_cart.png",
  DefaultProfileImage: "https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/profile.png",
  DefaultOrganizationLogo: "https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/ken42(png).png",
  AuthLayoutBg: "https://infivestoragedev.blob.core.windows.net/infive/pexels-josh-hild-2422497.jpg",
  NoDataTheme: "https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/noEvents.png",
  PostEventBanner: "https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/Plant.png",
  PostJobBanner: "https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/PostJob.png",
  EventCardCoverImage: "https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/noimage.jpg",
  OnBoardBg: "https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/backgroundonboard.png",
  Complain: "https://inazstgpfs3001.blob.core.windows.net/assets/Ken42%20Alumni/PNG/Objects.png",
};
const Svg = {};
const Icons = {
  Error: "https://inazstgpfs3001.blob.core.windows.net/assets/Kenverse/Alumni/Images/error.png",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { Images, Icons, Svg };
