const routes = {
  login: '/login',
  otpverification: '/otpverification',
  emailverification: '/emailverification',
  signup: '/signup',
  onboarding: '/onBoarding',
  forgotPassword: '/forgotPassword',
  changePassword: '/changePassword',
  institute: '/institute',
  registerSubInstitute: '/institute/register',
};

export default routes;
