import React from "react";
import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "../../Utils/routes";
import { getAxiosInstance, getConfig } from "../../Utils/apiService";
import { useAppContext } from "../../Helpers/Contexts/appContext";
import KenLoader from "../../GlobalComponents/KenLoader";
import axios from "axios";
// import { KEY_AZURE_TOKEN } from "../../Helpers/constant";
import { ConfigOptions } from "../../interfaces/Common";
import AuthLayout from "../../Components/AuthLayout";
import AppLayout from "../../Components/AppLayout";
import { PrivateRoute, PublicRoute } from "../../Utils/helperFunctions";

const VerifyOTP = React.lazy(() => import("../Auth/VerifyOtp/index"));
const EmailConformation = React.lazy(() => import("../Auth/ConfirmEmail/index"));
const Login = React.lazy(() => import("../Auth/Login/index"));
const ForgotPassword = React.lazy(() => import("../Auth/ForgotPassword/index"));
const ConfirmPassword = React.lazy(() => import("../Auth/ConfirmPassword/index"));
const DashBoard = React.lazy(() => import("../Dashboard"));
const InstituteDetails = React.lazy(() => import("../Dashboard/InstituteDetails"));
const PostSubInstitute = React.lazy(() => import("../Dashboard/PostSubInstitute"));

const MainLayout: React.FunctionComponent = () => {
  const {
    dispatch,
    state: { config },
  } = useAppContext();

  useEffect(() => {
    getConfig()
      .then((res: Array<ConfigOptions>) => {
        dispatch({ type: "updateConfig", value: res[0]?.config });
        dispatch({ type: "updateLogo", value: res[0]?.config?.logoURL });
        getAxiosInstance(res);
        try {
          axios({ method: "get", baseURL: res[0]?.config?.tokenUrl })
            .then((response: any) => {
              // localStorage.setItem(KEY_AZURE_TOKEN, response.data.access_token);
            })
            .catch((err) => {
              return err;
            });
        } catch (err) {
          console.error(err);
        }
      })
      .catch((err) => {
        console.error("error in config", err);
      });
  }, []);

  return config.apiBaseUrl === "" ? (
    <KenLoader />
  ) : (
    <>
      <Routes>
        {/* Initial Route */}
        <Route path="/" element={<Navigate to={routes.login} />} />

        {/* Public Routes */}
        <Route
          path={routes.login}
          index
          element={
            <PublicRoute>
              <AuthLayout>
                <Login />
              </AuthLayout>
            </PublicRoute>
          }
        />
        <Route
          path={routes.otpverification}
          element={
            <PublicRoute>
              <AuthLayout>
                <VerifyOTP />
              </AuthLayout>
            </PublicRoute>
          }
        />
        <Route
          path={routes.emailverification}
          element={
            <PublicRoute>
              <AuthLayout>
                <EmailConformation />
              </AuthLayout>
            </PublicRoute>
          }
        />
        <Route
          path={routes.forgotPassword}
          element={
            <PublicRoute>
              <AuthLayout>
                <ForgotPassword />
              </AuthLayout>
            </PublicRoute>
          }
        />

        <Route
          path={routes.changePassword}
          element={
            <PublicRoute>
              <AuthLayout>
                <ConfirmPassword />
              </AuthLayout>
            </PublicRoute>
          }
        />

        {/* Jobs */}

        <Route
          path={`${routes.institute}`}
          element={
            <PrivateRoute>
              <AppLayout>
                <DashBoard />
              </AppLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={`${routes.institute}/:instituteid`}
          element={
            <PrivateRoute>
              <AppLayout>
                <InstituteDetails />
              </AppLayout>
            </PrivateRoute>
          }
        />
        <Route
          path={`${routes.registerSubInstitute}`}
          element={
            <PrivateRoute>
              <AppLayout>
                <PostSubInstitute />
              </AppLayout>
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
};

export default MainLayout;
