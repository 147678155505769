import { Box, Grid, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactChildren } from "../../interfaces/Common";
import Image from "../../Assets/assetHelper";
import KenColors from "../../Helpers/Themes/kenColors";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
    minHeight: "100vh",
    width: "100%",
    overflow: "auto",
    position: "absolute",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundImage: `url(${Image.Images.AuthLayoutBg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      opacity: "60%",
      backgroundColor: theme.palette.KenColors.kenBlack,
    },
    "& > div": {
      position: "relative",
      zIndex: 1,
      right: 0,
      textAlign: "center",
      color: theme.palette.KenColors.kenWhite,
    },
  },
  heading: {
    fontSize: "40px !important",
    fontWeight: "600 !important",
    color: theme.palette.KenColors.kenWhite,
    textAlign: "left",
    width: "76%",
  },
  subHeading: {
    color: theme.palette.KenColors.kenWhite,
    width: "76%",
    textAlign: "start",
  },
  formContainer: {
    position: "relative",
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    minHeight: "100vh",
    width: "100%",
    alignItems: "center",
    background: theme.palette.KenColors.ClientLogin,
    padding: "20px 0px",
    overflow: "auto",
  },
  layoutBar: {
    background: theme.palette.KenColors.ClientBlue6,
    height: "9px",
    width: "98px",
    margin: "10px 0px",
  },
  boxLayout: {
    marginLeft: "24%",
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const AuthLayout: React.FunctionComponent<ReactChildren> = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={6}>
        <Box component={"div"} className={classes.boxLayout}>
          {/* <Typography className={classes.heading}>Impacting World Through Education</Typography>
          <div className={classes.layoutBar} />
          <Typography variant="medium" component={"h6"} className={classes.subHeading}>
            At Ken42, we break bounds, find new challenges and provide only the best for institutions across the globe
          </Typography>
          <Box display={"flex"} gap={"4px"} marginTop={"16px"}>
            <Box height={"8px"} width={"8px"} borderRadius={"8px"} bgcolor={KenColors.kenWhite} />
            <Box height={"8px"} width={"45px"} borderRadius={"8px"} bgcolor={KenColors.kenWhite} />
            <Box height={"8px"} width={"8px"} borderRadius={"8px"} bgcolor={KenColors.kenWhite} />
            <Box height={"8px"} width={"8px"} borderRadius={"8px"} bgcolor={KenColors.kenWhite} />
          </Box> */}
        </Box>
      </Grid>
      <Grid item xs={12} md={5} className={classes.formContainer}>
        {children}
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
