import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import KenColors from "./kenColors";
import { ReactChildren } from "../../interfaces/Common";

const theme = createTheme({
  palette: {
    KenColors: { ...KenColors },
    primary: {
      light: "#757ce8",
      main: "#092682",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
    error: {
      main: "#E75547",
    },
    success: {
      main: "#1BBE75",
    },
    card: {
      backgroundColor: "#F7F8FB",
      hover: "#EDF2FF",
    },
    labels: {
      main: "#061938",
      light: "#7A869A",
      medium: "#505F79",
    },
  },
  typography: {
    fontFamily: ["GeneralSans"].join(","),
    body1: {
      fontSize: "16px",
    },
    body2: {
      fontSize: "18px",
    },
    caption: {
      fontSize: "14px",
    },
    h1: {
      fontSize: "48px",
    },
    h2: {
      fontSize: "40px",
    },
    h3: {
      fontSize: "32px",
    },
    h4: {
      fontSize: "24px",
    },
    h5: {
      fontSize: "16px",
    },
    h6: {
      fontSize: "8px",
    },
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "extralargebold" },
          style: {
            fontSize: "24px",
            fontWeight: "600",
          },
        },
        {
          props: { variant: "extralargemedium" },
          style: {
            fontSize: "24px",
            fontWeight: "500",
          },
        },
        {
          props: { variant: "extralargethin" },
          style: {
            fontSize: "24px",
            fontWeight: "400",
          },
        },
        {
          props: { variant: "largebold" },
          style: {
            fontSize: "20px",
            fontWeight: "600",
          },
        },
        {
          props: { variant: "largemedium" },
          style: {
            fontSize: "20px",
            fontWeight: "500",
          },
        },
        {
          props: { variant: "largethin" },
          style: {
            fontSize: "20px",
            fontWeight: "400",
          },
        },
        {
          props: { variant: "mediumbold" },
          style: {
            fontSize: "16px",
            fontWeight: "600",
          },
        },
        {
          props: { variant: "medium" },
          style: {
            fontSize: "16px",
            fontWeight: "500",
          },
        },
        {
          props: { variant: "mediumthin" },
          style: {
            fontSize: "16px",
            fontWeight: "400",
          },
        },
        {
          props: { variant: "smallbold" },
          style: {
            fontSize: "14px",
            fontWeight: "600",
          },
        },
        {
          props: { variant: "smallmedium" },
          style: {
            fontSize: "14px",
            fontWeight: "500",
          },
        },
        {
          props: { variant: "smallthin" },
          style: {
            fontSize: "14px",
            fontWeight: "400",
          },
        },
        {
          props: { variant: "extrasmallbold" },
          style: {
            fontSize: "12px",
            fontWeight: "600",
          },
        },
        {
          props: { variant: "extrasmallmedium" },
          style: {
            fontSize: "12px",
            fontWeight: "500",
          },
        },
        {
          props: { variant: "extrasmallthin" },
          style: {
            fontSize: "12px",
            fontWeight: "400",
          },
        },
      ],
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          padding: "12px",
          borderRadius: "8px",
          boxShadow: "none !important",
        },
        disabled: {
          opacity: 0.5,
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            background: KenColors.ClientPrimary,
            color: KenColors.ClientGrey2,
            ":disabled": {
              color: KenColors.ClientGrey2,
            },
          },
        },
        {
          props: { variant: "containedII" },
          style: {
            background: KenColors.ClientBlue4,
            color: KenColors.ClientBlue6,
            ":disabled": {
              color: KenColors.ClientBlue6,
            },
          },
        },
        {
          props: { variant: "secondarycontained" },
          style: {
            background: KenColors.ClientPrimaryGrey,
            color: KenColors.ClientBlack2,
            ":disabled": {
              color: KenColors.ClientBlack2,
            },
          },
        },
        {
          props: { variant: "secondarycontainedII" },
          style: {
            background: KenColors.kenWhite,
            color: KenColors.ClientBlack2,
            borderRadius: "12px",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            ":disabled": {
              color: KenColors.ClientBlack2,
            },
          },
        },
        {
          props: { variant: "special" },
          style: {
            background: KenColors.ClientBlue4,
            color: KenColors.ClientBlue6,
            padding: "6px 8px",
            borderRadius: "12px",
            ":disabled": {
              color: KenColors.ClientBlue6,
            },
          },
        },
        {
          props: { variant: "errorcontained" },
          style: {
            background: KenColors.ClientRed2,
            color: KenColors.ClientRed,
            ":disabled": {
              color: KenColors.ClientRed,
            },
          },
        },
        {
          props: { variant: "text" },
          style: {
            color: KenColors.ClientBlack2,
            ":disabled": {
              color: KenColors.ClientBlack2,
            },
          },
        },
      ],
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const GlobalThemeProvider: React.FunctionComponent<ReactChildren> = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default GlobalThemeProvider;
