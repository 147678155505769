import React from "react";
import "react-calendar/dist/Calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import { I18nextProvider } from "react-i18next";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-phone-input-2/lib/style.css";
import { BrowserRouter as Router } from "react-router-dom";
import MainLayout from "./Containers/MainLayout";
import GlobalThemeProvider from "./Helpers/Themes/themeProvider";
import { AppContextProvider } from "./Helpers/Contexts/appContext";
import { AuthContextProvider } from "./Helpers/Contexts/authContext";
import i18next from "i18next";
import { SnackbarProvider } from "notistack";
import KenLoader from "./GlobalComponents/KenLoader";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./Helpers/ErrorFallback";
import { FormContextProvider } from "./Helpers/Contexts/formContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import AppSnackBar from "./Components/AppSnackbar";

function App() {
  return (
    <Router>
      <React.Suspense fallback={<KenLoader />}>
        <AppContextProvider>
          <AuthContextProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <FormContextProvider>
                <AppSnackBar>
                  <GlobalThemeProvider>
                    <I18nextProvider i18n={i18next}>
                      <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={() => {
                          window.location.reload();
                        }}
                      >
                        <div className="App">
                          <MainLayout />
                        </div>
                      </ErrorBoundary>
                    </I18nextProvider>
                  </GlobalThemeProvider>
                </AppSnackBar>
              </FormContextProvider>
            </LocalizationProvider>
          </AuthContextProvider>
        </AppContextProvider>
      </React.Suspense>
    </Router>
  );
}

export default App;
