import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import routes from "../../../Utils/routes";
import { useStyles } from "../styles";
import { ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import KenColors from "../../../Helpers/Themes/kenColors";

interface SideNavProps {
  handleListClick: (link: string, index: number) => void;
}

const SideNavTabs: React.FunctionComponent<SideNavProps> = ({ handleListClick }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const getFirstPath = pathname.split("/").filter((items: string) => {
    return items && items !== "";
  });

  const sideNavContents = [
    {
      icon: (isActive: boolean) => {
        return <WorkOutlineOutlinedIcon className={isActive ? classes.listItemIconActive : classes.listItemIcon} />;
      },
      label: "Dashboard",
      link: routes.institute,
      name: "institute",
    },
  ];

  return sideNavContents?.map((items, index) => {
    const selectedTab = getFirstPath[0] === items.name;
    return (
      <ListItemButton
        onClick={() => {
          handleListClick(items.link, index);
        }}
        style={{ marginTop: index === 0 ? "15px" : "4px" }}
        className={`${classes.listItemButton} ${selectedTab && classes.listItemButtonActive}`}
      >
        <ListItemIcon sx={{ minWidth: "40px" }}>{items.icon(selectedTab)}</ListItemIcon>
        <ListItemText className={`${classes.listItemText} ${selectedTab && classes.listItemTextActive}`}>
          <Typography color={selectedTab ? KenColors.kenWhite : KenColors.ClientBlack2} variant={selectedTab ? "mediumbold" : "mediumthin"}>
            {items.label}
          </Typography>
        </ListItemText>
      </ListItemButton>
    );
  });
};

export default SideNavTabs;
