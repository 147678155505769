import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    background: theme.palette.KenColors.kenWhite,
    padding: "34px 24px !important",
    textAlign: "start",
    fontFamily: "GeneralSans",
    boxShadow: "none !important",
  },
  nav: {
    background: theme.palette.KenColors.kenWhite,
    height: "90vh",
    borderRadius: "16px !important",
    minHeight: "90vh",
    boxShadow: "none !important",
    padding: "20px 0px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    position: "relative",
  },
  actions: {
    background: theme.palette.KenColors.kenWhite,
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 48px",
    alignItems: "center",
    borderRadius: "16px !important",
    boxShadow: "none !important",
  },
  actionLogo: {
    height: "72px",
    width: "72px",
    objectFit: "cover",
    borderRadius: "30px !important",
  },
  actionText: {
    whiteSpace: "wrap",
    fontSize: "16px",
    fontWeight: "500 !important",
    color: theme.palette.KenColors.ClientBlack,
  },
  profilImage: {
    height: "144px",
    width: "144px",
    borderRadius: "50px",
    objectFit: "cover",
    marginBottom: "24px",
  },
  profileName: {
    fontSize: "20px !important",
    fontWeight: "600 !important",
    color: theme.palette.KenColors.ClientBlack2,
  },
  profileDesignation: {
    fontSize: "16px !important",
    fontWeight: "400 !important",
    color: theme.palette.KenColors.ClientBlack2,
  },
  listItemButton: {
    borderRadius: "10px 0px 0px 10px !important",
    marginLeft: "10px !important",
  },
  listItemButtonActive: {
    background: theme.palette.KenColors.ClientPrimary,
  },
  listItemIcon: {
    color: theme.palette.KenColors.grey,
  },
  listItemIconActive: {
    color: theme.palette.KenColors.kenWhite,
  },
  listItemText: {
    color: `${theme.palette.KenColors.ClientBlack2} !important`,
    fontSize: "16px !important",
    fontWeight: "400 !important",
  },
  listItemTextActive: {
    color: `${theme.palette.KenColors.kenWhite} !important`,
    fontSize: "15px !important",
    fontWeight: "600 !important",
  },
}));
